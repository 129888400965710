<template>
  <div class='trial-labs-radar-chart'>
    <canvas id="myRadarChart"></canvas>
    <div class="label">
      <div class="label-container">
        <div class="text-container">
          <div class="box"/>
          <div class="text">{{ $t('label_radar_chart') }}</div>
        </div>
        <div v-if="isPhoneMode">
          <div class="description" v-for="(comment, index) in this.categoryLabel" :key="index">
            <div class="label-category">{{ comment[0] }} - {{ comment[1] }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { isMobile } from '@/common/browser.util';

export default {
  name: 'TrialLabsRadarChart',
  data() {
    return {
      radarChart: null,
      categoryLabel: [],
    };
  },
  props: {
    data: Array,
  },
  mounted() {
    this.getChart();
  },
  computed: {
    ...mapGetters(['participantToken']),
    isMobile,
    isFreePass() {
      return this.participantToken.startsWith('FREEPASS');
    },
    isPhoneMode() {
      if (window.screen.width < 700) {
        return true;
      }
      return this.isMobile && !this.isFreePass;
    },
  },
  methods: {
    pointStyle(stringAverageArray) {
      const averageArray = stringAverageArray.map(value => value * 1);
      const max = Math.max(...averageArray);
      const min = Math.min(...averageArray);
      const valueArr = averageArray.map(() => true);
      let styleArr = [];
      let colorArr = [];
      let radiusArr = [];
      if (min !== max) {
        styleArr = averageArray.map(value => ((value === min) ? 'triangle' : 'circle'));
        colorArr = averageArray.map((value) => {
          if (value === min) return 'red';
          if (value === max) return '#00CA94';
          return '#9B9B9B';
        });
        radiusArr = averageArray.map(value => ((value === max || value === min) ? '4' : '3'));
      }
      const offsetArr = averageArray.map(value => ((value > 4.0) ? -47 : -2));
      if (averageArray[0] > 4.0) offsetArr[0] = -40;
      return {
        value: valueArr,
        style: styleArr,
        color: colorArr,
        radius: radiusArr,
        offset: offsetArr,
      };
    },
    getChart() {
      let category = this.data.map(c => c.category.split('\n'));
      if (this.isPhoneMode) {
        category = this.data.map(c => c.category);
        this.categoryLabel = category.map((item, index) => [index + 1, category[index]]);
        category = category.map((item, index) => index + 1);
      }
      const average = this.data.map(c => c.average);
      const chart = document.getElementById('myRadarChart');
      const maxValue = this.pointStyle(average);
      const fontSize = this.isPhoneMode ? 10 : 20;
      const pointLabels = {
        fontSize,
        fontcolor: '#000000',
        lineHeight: 1.29,
        textAlign: 'right',
        color: '#2a324b',
      };
      if (this.radarChart) {
        this.radarChart.destroy();
      }
      if (this.isPhoneMode) {
        chart.width = 300;
        chart.height = 200;
      }
      this.radarChart = new Chart(chart, {
        type: 'radar',
        plugins: [ChartDataLabels],
        data: {
          labels: category,
          datasets: [
            {
              label: 'Category Average',
              data: average,
              borderColor: 'rgba(122, 211, 187, 0.64)',
              borderWidth: 1.5,
              backgroundColor: 'rgba(0, 202, 148, 0.05)',
              pointStyle: maxValue.style,
              radius: maxValue.radius,
              pointBorderColor: maxValue.color,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scale: {
            ticks: {
              suggetedMin: 0,
              max: 5,
              beginAtZero: true,
              stepSize: 1,
            },
            gridLines: {
              circular: true,
            },
            pointLabels,
          },
          plugins: {
            datalabels: {
              display: maxValue.value,
              color: maxValue.color,
              anchor: 'end',
              align: 'end',
              offset: maxValue.offset,
              font: {
                size: 15,
              },
            },
          },
          tooltips: {
            callbacks: {
              /* eslint dot-notation: "off" */
              title: (tooltipItem, data) => data['labels'][tooltipItem[0]['index']],
              label: (tooltipItem, data) => data['datasets'][0]['data'][tooltipItem['index']],
            },
          },
        },
      });
      return this.radarChart;
    },
  },
  watch: {
    data() {
      this.getChart();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";

.trial-labs-radar-chart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    .label-container {
      display: flex;
      flex-direction: column;

      .text-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 28px;

        .box {
          width: 20px;
          height: 7px;
          margin-right: 8px;
          border: solid 1.5px #00ca94;
          background-color: #e5faf4;
        }

        .text {
          font-size: $font_size_12;
          line-height: 1.75;
          letter-spacing: normal;
          color: #2a324b;
        }
      }

      .description {
        .label-category {
          font-size: 16px;
          color: #2a324b;
        }
      }
    }
  }
}

@media only screen and (max-width: $max_width_size_for_mobile) {
  .trial-labs-radar-chart {
    //width: 720px !important;
    margin-top: 50px;
    width: 100% !important;
    height: 80% !important;
  }
}
</style>
